<template>
  <b-container fluid>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
            <table border="0" style="width: 100%">
            <tr>
                <td style="width:50%">{{$t('globalTrans.comments')}} :
                <slot>
                    {{ $i18n.locale === 'bn' ? ActionData.applicationData[0].comment_bn_4357 : ActionData.applicationData[0].comment_en_4495 }}
                </slot>
                </td>
                <td style="width:50%">{{$t('globalTrans.attachment')}} :
                <slot>
                    <a target="_blank" v-if="getFile(ActionData.applicationData[0].attachment_7865) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(ActionData.applicationData[0].attachment_7865)"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                    <!-- {{ getColumnName($store.state.commonObj.divisionList, ActionData.applicationData[0].division_4819) }} -->
                </slot>
                </td>
            </tr>
            </table>
        </div>
    </div>
  </b-container>
</template>
<script>
import { licenseRegistrationServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['ActionData', 'isAdmin'],
  data () {
    return {
      loading: false,
      badcNew: [],
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl
    }
  },
  methods: {
    getFile (value) {
      if (value === '') {
         return false
      }
      if (value) {
        const fileData = JSON.parse(value)
        if (fileData[0]) {
          return fileData[0]
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>
