<template>
    <b-container fluid>
        <div>
            <!-- 3rd tab design -->
            <!-- <template v-if="labelDesignShow"> -->
                <!-- fertilizer information part  -->
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'আমদানিকৃত সারের নাম' : 'আমদানিকৃত সারের নাম' }}</h5><!----></div><!----><!---->
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                        <table border="0" style="width: 100%">
                        <tr>
                            <td style="width:50%">{{$t('li_step.brand_name')}} :
                            <slot>
                                {{ badcStaticData[2].brand_name_4430 }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_22) }}
                            </slot> -->
                            </td>
                            <td style="width:50%">{{$t('dam-config.chemical_name')}} :
                            <slot>
                                {{ badcStaticData[2].chemical_n_1764 }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'পুষ্টি উপাদানের নাম' : 'Nutrient Name' }} :
                            <slot>
                                {{ badcStaticData[2].product_na_9394 }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                            </slot> -->
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'পুষ্টি উপাদানের শতকরা হার' : 'Percentage of Nutrients' }} : {{ badcStaticData[2].percentage_9497 }}</td>
                        </tr>
                        <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'সারের নিট ওজন' : 'Net Weight of Fertilizer' }} :
                            <slot>
                                {{ badcStaticData[2]._amount__8186 }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                            </slot> -->
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'ব্যাচ নং/লট নং' : 'Batch No./Lot No.' }} : {{ badcStaticData[2].batch_no___3070 }}</td>
                        </tr>
                        <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'উৎপাদনের তারিখ' : 'Date of production' }} :
                            <slot>
                                {{ badcStaticData[2].date_of_pr_8517 | dateFormat }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                            </slot> -->
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'সর্বোচ্চ খুচরা মূল্য' : 'Maximum retail price' }} : {{ badcStaticData[2].maximum_re_9356 }}</td>
                        </tr>
                        </table>
                    </div>
                </div>

                <!-- register fertilizer information part  -->
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12"><!----><!----><!----><!----><h5 data-v-d02552b8="" class="customBorder"> {{ $i18n.locale === 'bn' ? 'আমদানীকারকের তথ্যঃ' : 'আমদানীকারকের তথ্যঃ' }}</h5><!----></div><!----><!---->
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin-left: 50px;margin-top: 20px;margin-bottom:20px">
                        <table border="0" style="width: 100%">
                        <tr>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'আমদানীকারকের নাম' : 'Importer Name' }} :
                            <slot>
                                {{ badcStaticData[2].distributo_2403 }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, badcStaticData[0].division_22) }}
                            </slot> -->
                            </td>
                            <td style="width:50%">{{ $i18n.locale === 'bn' ? 'আমদানীকারকের মোবাইল নম্বর' : 'Importer Mobile No.' }} :
                            <slot>
                                {{ badcStaticData[2].manufactur_7737 | mobileNumber }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, badcStaticData[0].district_23) }}
                            </slot> -->
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'বিভাগ' : 'Division' }} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.divisionList, badcStaticData[2].division_6772) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                            </slot> -->
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'জেলা' : 'District' }} :  {{ getColumnName($store.state.commonObj.districtList, badcStaticData[2].district_8607) }}</td>
                        </tr>
                        <tr>
                            <td>{{ $i18n.locale === 'bn' ? 'উপজেলা' : 'Upazila' }} :
                            <slot>
                                {{ getColumnName($store.state.commonObj.upazilaList, badcStaticData[2].upazila_7209) }}
                            </slot>
                            <!-- <slot v-else>
                                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, badcStaticData[0].upazilla_24) }}
                            </slot> -->
                            </td>
                            <td>{{ $i18n.locale === 'bn' ? 'আমদানীকারকের ঠিকানা' : 'Importer Address' }} : {{  $i18n.locale === 'bn' ? badcStaticData[2].importer_a_7902 : badcStaticData[2].importer_a_3177 }}</td>
                        </tr>
                        </table>
                    </div>
                </div>

            <!-- </template> -->
        </div>
    </b-container>
</template>

<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
// import Receipt from '@/modules/license-registration-service/license-step/pages/steps/Receipt'
// import ReceiptOffline from '@/modules/license-registration-service/license-step/pages/steps/ReceiptOffline'

export default {
  props: ['tabData', 'badcStaticData', 'history', 'users', 'stepData'],
//   components: {
//     Receipt, ReceiptOffline
//   },
  data () {
    return {
        isLoad: false,
        datas: {},
        generalInfoShow: true,
        attachmentShow: false,
        labelDesignShow: false,
        reportLoading: false,
        paymentRecipt: false,
        reportList: [],
        import: [
            { id: 1, file: 'image_73', yes_no: 1, remarks: null },
            { id: 2, file: 'rent_agree_5997', yes_no: 1, remarks: null },
            { id: 3, file: 'nid_79', yes_no: 0, remarks: null },
            { id: 4, file: 'updated_tr_5857', yes_no: 0, remarks: null },
            { id: 5, file: 'tin_certif_1997', yes_no: 0, remarks: null },
            { id: 6, file: 'vat_regist_7591', yes_no: 0, remarks: null },
            { id: 7, file: 'updated_in_80', yes_no: 0, remarks: null },
            { id: 8, file: 'updated_ir_8033', yes_no: 0, remarks: null },
            { id: 9, file: 'updated_bf_3787', yes_no: 0, remarks: null },
            { id: 10, file: 'uptodate_b_2747', yes_no: 0, remarks: null },
            { id: 11, file: 'undertakin_8030', yes_no: 0, remarks: null },
            { id: 12, file: 'address_an_7107', yes_no: 0, remarks: null },
            { id: 13, file: 'photocopy__4458', yes_no: 0, remarks: null },
            { id: 14, file: 'authorizat_7806', yes_no: 0, remarks: null },
            { id: 15, file: 'paclobutra_7492', yes_no: 0, remarks: null },
            { id: 16, file: 'approved_s_9860', yes_no: 0, remarks: null },
            { id: 17, file: 'small_and__8570', yes_no: 0, remarks: null },
            { id: 18, file: '_year_perf_2280', yes_no: 0, remarks: null },
            { id: 19, file: 'old_regist_6668', yes_no: 0, remarks: null }
        ],
        daeList: []
    }
  },
  created () {
    this.daeList = this.import
    this.loadData()
  },
  computed: {
    local: function () {
        return this.$i18n.locale
    },
    ingridentList: function () {
      return this.$store.state.licenseRegistration.commonObj.activeIngredientList.filter(item => item.status === 1)
    },
    labList: function () {
       return this.$store.state.licenseRegistration.commonObj.labList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCountryManufactureName (id) {
        const obj = this.$store.state.licenseRegistration.commonObj.countryList.find(el => el.value === parseInt(id))
        if (typeof obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    getCompanyName (id) {
        const obj = this.$store.state.licenseRegistration.commonObj.foreignEnlishmentList.find(el => el.value === parseInt(id))
        if (typeof obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
    },
    checkFileFunction (field) {
      return field
    },
    getFile (value) {
      if (value === '') {
         return false
      }
      if (value) {
        const fileData = JSON.parse(value)
        if (fileData[0]) {
          return fileData[0]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    loadData () {
        const search = {
            service_id: this.badcStaticData[0].service_id,
            application_id: this.badcStaticData[0].application_id
        }
        const params = Object.assign({}, search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/dae-attachment/search', params).then(response => {
            if (response.success) {
                this.datas = response.data
                this.isLoad = true
            } else {
                this.isLoad = true
            }
        })
    },
    tabChanged () {
        this.attachmentShow = false
        this.labelDesignShow = false
        this.generalInfoShow = false
    },
    tabChange (val) {
        if (val === 0) {
            this.attachmentShow = false
            this.labelDesignShow = false
            this.generalInfoShow = true
        }
        if (val === 1) {
            this.labelDesignShow = false
            this.generalInfoShow = false
            this.attachmentShow = true
        }
        if (val === 2) {
            this.generalInfoShow = false
            this.attachmentShow = false
            this.labelDesignShow = true
        }
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    async getreportData () {
        this.generalInfoShow = false
        this.attachmentShow = false
        this.labelDesignShow = false
        this.reportLoading = true
        const applicationID = parseInt(this.badcStaticData[0].application_id)
        await RestApi.getData(licenseRegistrationServiceBaseUrl, `${'/configuration/dae-lab-assign/lab-assign'}/${applicationID}`).then(response => {
            if (response.success) {
                if (response.data.length > 0) {
                    this.reportList = response.data[0].lab_details
                }
            }
            this.reportLoading = false
        })
    },
    getLabName (labId) {
      const lab = this.labList.find(item => item.value === labId)
      if (this.$i18n.locale === 'en') {
        return lab.text_en
      } else {
        return lab.text_bn
      }
    },
    getIngredientName (ingredientId) {
      const ingredient = this.ingridentList.find(item => item.value === ingredientId)
      if (this.$i18n.locale === 'en') {
        return ingredient.text_en
      } else {
        return ingredient.text_bn
      }
    },
    getUserName (userId) {
      const user = this.users.find(user => user.value === userId)
      return user !== undefined ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    printLicense () {
        const search = {
            id: 9,
            service_id: parseInt(this.badcStaticData[0].service_id),
            step_id: parseInt(this.stepData.step_id),
            applicationID: parseInt(this.badcStaticData[0].application_id),
            org_id: parseInt(this.badcStaticData[0].org_id),
            type: 'pdf',
            local: this.$i18n.locale
        }
        const params = Object.assign({}, search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(licenseRegistrationServiceBaseUrl, '/step/step-from-data', params).then(response => {
            if (response.success) {
                this.datas = response.data
                this.isLoad = true
            } else {
                this.isLoad = true
            }
        })
    }
  }
}

</script>
