<template>
  <b-container fluid>
    <!-- <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <h4 class="">{{ $i18n.locale === 'bn' ? 'বাংলাদেশ কৃষি উন্নয়ন কর্পোরেশন' : 'Bangladesh Agricultural Development Corporation' }}</h4>
    </div> -->
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <h5 class="">{{ $i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture' }}</h5>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 text-center">
        <h4 class="">{{ $i18n.locale === 'bn' ? 'বাংলাদেশ কৃষি উন্নয়ন কর্পোরেশন' : 'Ministry of Agriculture' }}</h4>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
      <h6> {{ $i18n.locale === 'bn' ? 'সেচ যন্ত্রের লাইসেন্সের জন্য সেচস্কীম পরিদর্শন' : 'Irrigation Pump Inspection Form' }}</h6>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 pl-10 pr-10">
        <b-table-simple borderless striped hover small caption-top responsive>
          <b-tr>
            <b-td style="width: 15%">{{$t('globalTrans.date')}}</b-td>
            <b-td style="width: 5%">:</b-td>
            <b-td style="width: 20%">{{ ActionData.applicationData[0].date_6407 | dateFormat }}</b-td>
            <b-td style="width: 15%"></b-td>
            <b-td style="width: 5%"></b-td>
            <b-td style="width: 20%"></b-td>
          </b-tr>
          <b-tr>
            <b-td>{{$t('org_pro_division.division')}} </b-td>
            <b-td>:</b-td>
            <b-td>
              <slot v-if="isAdmin">
                {{ getColumnName($store.state.commonObj.divisionList, ActionData.applicationData[0].division_3298) }}
              </slot>
              <slot v-else>
                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, ActionData.applicationData[0].division_3298) }}
              </slot>
            </b-td>
            <b-td>{{$t('org_pro_district.district')}} </b-td>
            <b-td>:</b-td>
            <b-td>
              <slot v-if="isAdmin">
                {{ getColumnName($store.state.commonObj.districtList, ActionData.applicationData[0].district_3351) }}
              </slot>
              <slot v-else>
                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, ActionData.applicationData[0].district_3351) }}
              </slot>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{$t('org_pro_upazilla.upazilla')}} </b-td>
            <b-td>:</b-td>
            <b-td>
              <slot v-if="isAdmin">
                {{ getColumnName($store.state.commonObj.upazilaList, ActionData.applicationData[0].upazilla_8125) }}
              </slot>
              <slot v-else>
                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.upazilaList, ActionData.applicationData[0].upazilla_8125) }}
              </slot>
            </b-td>
            <b-td>{{$t('globalTrans.union')}} </b-td>
            <b-td>:</b-td>
            <b-td>
              <slot v-if="isAdmin">
                {{ getColumnName($store.state.commonObj.unionList, ActionData.applicationData[0].union_1258) }}
              </slot>
              <slot v-else>
                {{ getColumnName($store.state.ExternalUserIrrigation.commonObj.unionList, ActionData.applicationData[0].union_1258) }}
              </slot>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{ $i18n.locale === 'bn' ? 'মৌজা' : 'Mouza' }} </b-td>
            <b-td>:</b-td>
            <b-td>{{ ActionData.applicationData[0].mouzabn____4629 }}</b-td>
            <b-td> {{ $i18n.locale === 'bn' ? 'গ্রাম' : 'Village' }} </b-td>
            <b-td>:</b-td>
            <b-td>
              {{ ActionData.applicationData[0].villagebn__2320 }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{ $i18n.locale === 'bn' ? 'জেএল নং' : 'JL No' }} </b-td>
            <b-td>:</b-td>
            <b-td>{{ ActionData.applicationData[0].jl_no_8092 }}</b-td>
            <b-td> {{ $i18n.locale === 'bn' ? 'খতিয়ান' : 'Ledger' }} </b-td>
            <b-td>:</b-td>
            <b-td>
              {{ ActionData.applicationData[0].ledger_2465 }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{ $i18n.locale === 'bn' ? 'দাগ নং' : 'Plot No' }} </b-td>
            <b-td>:</b-td>
            <b-td>{{ ActionData.applicationData[0].line_no___2930 }}</b-td>
            <b-td> {{ $i18n.locale === 'bn' ? 'ইঞ্চি' : 'Inch' }} </b-td>
            <b-td>:</b-td>
            <b-td>
              {{ ActionData.applicationData[0].inch__5820 }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{ $i18n.locale === 'bn' ? 'ম্যানেজার নাম (বাংলা)' : 'Manager Name(English)' }} </b-td>
            <b-td>:</b-td>
            <b-td>{{ $i18n.locale === 'bn' ? ActionData.applicationData[0].manager_na_1 : ActionData.applicationData[0].manager_na_7972 }}</b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td>
            </b-td>
          </b-tr>
        </b-table-simple>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 pl-10 pr-10">
        <h5> {{ $i18n.locale === 'bn' ? 'পরিদর্শনে নিম্নোক্ত বিষয়াদি যাচাই বাছাই করে প্রতিবেদন প্রদান করা হলো' : 'During the inspection, the following issues were verified and selected and the report was given' }}</h5>
        <hr/>
        <b-table-simple borderless striped hover small caption-top responsive>
          <b-tr>
            <b-td style="width:40%">{{ $i18n.locale === 'bn' ? ' ১। যে স্থানে নলকূপ স্থাপন করা হবে সেই স্থানের পানিধারক স্তর মাটির নিচে (মিটার)' : '1. The water table of the place where the tube well will be installed is below' }}</b-td>
            <b-td> {{ ActionData.applicationData[0]._the_water_8677 }}</b-td>
          </b-tr>
          <b-tr>
            <b-td style="width:40%">{{ $i18n.locale === 'bn' ? ' ২। পার্শ্ববর্তী নদী-নালা, খাল-বিল থেকে দূরত্ব(মিটার)' : '2. Distance from nearby rivers, canals (m)' }}</b-td>
            <b-td> {{ ActionData.applicationData[0]._distance__5687 }}</b-td>
          </b-tr>
          <b-tr>
            <b-td style="width:40%">{{ $i18n.locale === 'bn' ? ' ৩। নিকটবর্তী অন্যান্য নলকূপের দূরত্ব' : '3. Distance to other nearby tube wells' }}</b-td>
            <b-td> {{ ActionData.applicationData[0]._distance__3540 }}</b-td>
          </b-tr>
          <b-tr>
            <b-td style="width:40%">{{ $i18n.locale === 'bn' ? ' ম্যানেজার নাম' : 'Manager Name' }}</b-td>
            <b-td> {{ $i18n.locale === 'bn' ? ActionData.applicationData[0].manager_na_9017 : ActionData.applicationData[0].manager_na_7512 }}</b-td>
          </b-tr>
          <b-tr>
            <b-td style="width:40%">{{ $i18n.locale === 'bn' ? ' পিতার নাম' : 'Fathers Name' }}</b-td>
            <b-td> {{ $i18n.locale === 'bn' ? ActionData.applicationData[0].fathers_na_3808 : ActionData.applicationData[0].fathers_na_1786 }}</b-td>
          </b-tr>
          <b-tr>
            <b-td style="width:40%">{{ $i18n.locale === 'bn' ? ' মোবাইল নাম্বার' : 'Mobile Number' }}</b-td>
            <b-td> {{ ActionData.applicationData[0].mobile_num_1449 }}</b-td>
          </b-tr>
        </b-table-simple>
        <b-table-simple borderless striped hover small caption-top responsive>
          <b-tr>
            <b-td style="width:70%">{{ $i18n.locale === 'bn' ? ' ৪। নলকূপের দ্বারা উপকৃত হইবে এইরূপ সম্ভাব্য এলাকার পরিমাণ (একর)' : '4. Amount of potential area (acre) to be benefited by tube well' }}</b-td>
            <b-td> {{ ActionData.applicationData[0]._amount_of_1449 }}</b-td>
          </b-tr>
          <b-tr>
            <b-td style="width:70%">{{ $i18n.locale === 'bn' ? ' ৫। গৃহস্থলীর উদ্দেশ্যে ব্যবহার্য নলকূপসহ অন্যান্য নলকূপের উপর সম্ভাব্য প্রভাব' : '5. Potential effects on other tube wells, including tube wells used for household purposes' }}</b-td>
            <b-td>
              {{ yesNoChecking(ActionData.applicationData[0]._potential_6000) }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td style="width:70%">{{ $i18n.locale === 'bn' ? ' ৬। নলকূপ স্থাপনের জন্য খনন স্থানে উপযুক্ততা' : '6. Suitable for excavation site for tube well installation' }}</b-td>
            <b-td>
              {{ yesNoChecking(ActionData.applicationData[0]._suitable__3904) }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td style="width:70%">{{ $i18n.locale === 'bn' ? ' ৭। যে এলাকায় নলকূপ স্থাপন করা হইবে সেই এলাকায় কৃষি কাজ উপকৃত হইবে' : '4. Amount of potential area (acre) to be benefited by tube well' }}</b-td>
            <b-td>
              {{ yesNoChecking(ActionData.applicationData[0]._agricultu_1084) }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td style="width:70%">{{ $i18n.locale === 'bn' ? '৮। পারিপার্শ্বিক পরিবেশের উপর বিরূপ প্রভাব পরিবে কি না' : '8. Whether it will have an adverse effect on the surrounding environment' }}</b-td>
            <b-td>
              {{ yesNoChecking(ActionData.applicationData[0]._whether_i_2655) }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td style="width:70%">{{ $i18n.locale === 'bn' ? '৯। অন্য কোন ভাবে বাস্তবায়ন যোগ্য কি না' : '9. Implemented in any other way' }}</b-td>
            <b-td>
              {{ yesNoChecking(ActionData.applicationData[0]._implement_7881) }}
            </b-td>
          </b-tr>
        </b-table-simple>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 pl-10 pr-10">
        <b-table-simple border small caption-top responsive>
          <b-thead>
            <b-tr>
              <b-th style="width:20%">{{ $i18n.locale === 'bn' ? ' ক্রমিক নং' : 'SL' }}</b-th>
              <b-th style="width:20%">{{ $i18n.locale === 'bn' ? ' স্বাক্ষীর নাম' : 'Witness Name' }}</b-th>
              <b-th style="width:20%">{{ $i18n.locale === 'bn' ? ' স্বাক্ষীর পিতার নাম' : 'Witness Fathers Name' }}</b-th>
              <b-th style="width:20%">{{ $i18n.locale === 'bn' ? ' মোবাইল নাম্বার' : 'Mobile Number' }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="ActionData.applicationData[0].add_more_5424">
             <b-tr v-for="(info, index) in JSON.parse(ActionData.applicationData[0].add_more_5424)" :key="index">
              <b-td style="width:20%">{{ $n(index + 1) }}</b-td>
              <b-td style="width:20%">
                <slot v-if="$i18n.locale === 'bn'">
                  {{ JSON.parse(ActionData.applicationData[0].add_more_5424)[index]['Witness Name(Bangla)']}}
                </slot>
                <slot v-else>
                  {{ JSON.parse(ActionData.applicationData[0].add_more_5424)[index]['Witness Name(English)']}}
                </slot>
              </b-td>
              <b-td style="width:20%">
                <slot v-if="$i18n.locale === 'bn'">
                  {{ JSON.parse(ActionData.applicationData[0].add_more_5424)[index]['Witness Fathers Name(Bangla)']}}
                </slot>
                <slot v-else>
                  {{ JSON.parse(ActionData.applicationData[0].add_more_5424)[index]['Witness Fathers Name(English)']}}
                </slot>
              </b-td>
              <b-td style="width:20%">{{ JSON.parse(ActionData.applicationData[0].add_more_5424)[index]['Mobile Number']}}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
       </div>
    </div>
  </b-container>
</template>
<script>
import { licenseRegistrationServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['ActionData', 'isAdmin'],
  data () {
    return {
      loading: false,
      badcNew: [],
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl
    }
  },
  created () {
  },
  methods: {
    yesNoChecking (val) {
      if (val === 'Yes') {
        return this.$i18n.locale === 'bn' ? 'হ্যাঁ' : 'Yes'
      }
      return this.$i18n.locale === 'bn' ? 'না' : 'No'
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getColumnNameOt (list, groupId) {
      const obj = list.find(item => parseInt(item.value) === parseInt(groupId))
      if (typeof obj !== 'undefined') {
        return obj.text
      } else {
          return ''
      }
    },
    addMoreDataReturn (index, value) {
      let newDD = ''
      const allJsonData = JSON.parse(this.badcStaticData[0].add_more_3656)[index]
      Object.keys(allJsonData).forEach(key => {
        if (key === 'Nearest  Tubewell  Capacity (Cusec)') {
          const checkData = allJsonData['Nearest  Tubewell  Capacity (Cusec)']
          newDD = checkData
        }
      })
      return newDD
    }
  }
}
</script>
